<!--
   User: Liu Yin
   Date: 2020/2/27
   Description:
 -->
<template>
  <article>
    <user-header></user-header>
    <section class="login-content">
      <div class="login-form-parent">
        <div class="login-form" style="display: flex;align-items: center;justify-content: center;flex-direction: column">
          <el-form ref="loginForm" style="width: 65%;text-align: center;" :model="loginForm" :rules="loginRules" label-position="left">
            <p class="login-title">企业登录</p>
            <el-form-item prop="username">
              <el-input ref="username" v-model="loginForm.username" placeholder="请输入账号" type="text" class="login-input">
                <i slot="prefix" class="el-input__icon el-icon-user-solid"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="loginForm.password" type="password" placeholder="请输入密码" class="login-input">
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="imageCode">
              <div style="display: flex">
                <el-input v-model="loginForm.imageCode" placeholder="请输入校验码" class="login-input" @keyup.enter.native="handleLogin">
                  <i slot="prefix" class="el-input__icon el-icon-key"></i>
                </el-input>
                <img id="img" :src="imageUrl" alt="" @click="getVerifyCode">
              </div>

            </el-form-item>
            <el-form-item style="text-align: left;">
              <el-checkbox v-if="loginType === '企业登录'" v-model="roleType"><span class="remember-account">我是企业员工</span></el-checkbox>
            </el-form-item>

            <el-button size="small" round class="login-btn" style="width: 100%" type="primary" @click="handleLogin">登录</el-button>

          </el-form>
          <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 25px;width: 100%">
            <!-- <el-button type="text" @click="handlePersonLogin">个人用户登录</el-button> -->
            <!-- <p v-if="this.$store.getters.loginType !== '1'" style="text-align: right;width: 100%;margin-right: 30px;font-size: 13px;color: #DC0101;cursor: pointer" @click="toRegister()">没有账号，请点击注册>></p> -->
          </div>

        </div>
      </div>
    </section>
    <bottom-navigate></bottom-navigate>
  </article>
</template>

<script>
import bottomNavigate from '../../../../components/bottom-navigate'
import userHeader from '../../../../components/header/userHeader'
import { Encrypt } from '../../../../utils/secret'
import { setToken, setUserInfo, setParkToken, setEnterpriseToken } from '../../../../utils/auth'
export default {
  components: {
    bottomNavigate,

    userHeader
  },
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
        imageCode: ''
      },
      roleType: false,
      loginRules: {
        username: [{ required: true, trigger: 'blur', message: '请输入用户名' }],
        password: [{ required: true, trigger: 'blur', message: '请输入密码' }],
        imageCode: [{ required: true, trigger: 'blur', message: '请输入验证码' }]
      },
      imageUrl: this.urls.BASE_URL + this.urls.verifyCode + '?id=' + (new Date().getTime()),
      redirect: ''
    }
  },
  computed: {
    loginType: {
      get() {
        return this.$store.getters.loginType === '1' ? '园区登录' : '企业登录'
      },
      set() {

      }
    }
  },
  watch: {
    $route: {
      // eslint-disable-next-line space-before-function-paren
      handler: function (route) {
        const query = route.query
        if (query && query.redirect) {
          let params = ''
          for (const key in query) {
            if (key === 'redirect') {
              continue
            }
            params = params.concat(`&${key}=${query[key]}`)
          }
          if (params) {
            this.redirect = query.redirect + '?' + params
          } else {
            this.redirect = query.redirect
          }
        }
        console.log('this.redirect==', this.redirect)
      },
      immediate: true
    }
  },
  mounted() {
    // const type = this.$route.query.type
    // console.log('type===', type, typeof type)
    // this.$store.dispatch('app/setLoginType', type)
    this.getParkInfo()
  },
  methods: {
    getParkInfo() {
      // 获取园区信息
      this.$get(this.urls.getParkInfo, {}, [1]).then(result => {
        if (result.code === 'SUCCESS') {
          const data = result.data
          this.$store.dispatch('app/setParkData', data)
        } else {
          this.$message.error('查询失败')
        }
      }).catch(() => {
        this.$message.error('查询失败')
      })
    },
    getVerifyCode() {
      this.imageUrl = this.urls.BASE_URL + this.urls.verifyCode + '?id=' + (new Date().getTime())
    },

    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.submitLogin()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    submitLogin() {
      // if (this.loginType === '园区登录') {
      //   this.loginForm.roleType = 'W9X8K2J7F'
      // } else {
      if (this.roleType) {
        this.loginForm.roleType = 'L4M9N7P2Q'
      } else {
        this.loginForm.roleType = 'G5H3E1R6T'
      }
      // }
      const form = {}
      for (const i in this.loginForm) {
        form[i] = this.loginForm[i]
      }
      form.password = Encrypt(form.password)
      this.toggleLoading(true)
      this.$postForm(this.urls.login, form).then(result => {
        if (result.code === 'SUCCESS') {
          if (this.loginType === '园区登录' && result.user.peopleType === '1') {
            // localStorage.setItem('details', JSON.stringify(result.details))
            this.saveInfo(result)
          } else if (this.loginType === '企业登录' && result.user.peopleType === '2') {
            // localStorage.setItem('details', JSON.stringify(result.details))
            this.saveInfo(result)
          } else if (this.loginType === '企业登录' && result.user.peopleType === '3') {
            // localStorage.setItem('details', JSON.stringify(result.details))
            this.saveInfo(result)
          } else {
            this.$message.error('您的账号暂无权限登录!')
          }
        } else {
          this.$message.error(result.message || result.msg)
          this.getVerifyCode()
        }
        this.toggleLoading(false)
      }).catch(() => {
        this.toggleLoading(false)
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    // 存储信息
    async saveInfo(data) {
      await setToken(data.token)
      await setUserInfo(data.user, data.token)
      if (this.redirect && this.redirect !== '/login') {
        location.href = `${this.redirect}`
      } else {
        location.href = '/'
      }
    },
    handlePersonLogin() {
      location.href = '/mutual-portal/login'
    },
    setSystemToken(user, token) {
      const peopleType = user.peopleType
      if (peopleType === '1') {
        // 园区系统用户
        setParkToken(token)
        if (this.redirect && this.redirect !== '/login') {
          location.href = `${this.redirect}`
        } else {
          this.toPark()
        }
      } else if (peopleType === '2') {
        // 企业用户
        setEnterpriseToken(token)
        if (this.redirect && this.redirect !== '/login') {
          location.href = `${this.redirect}`
        } else {
          this.toEnterprise()
        }
      }
    },
    // 进入园区后台
    toPark() {
      const url = process.env.VUE_APP_PARK_URL
      location.href = url
    },
    // 进入企业后台
    toEnterprise() {
      const enterpriseUrl = process.env.VUE_APP_ENTERPRISE_URL
      location.href = enterpriseUrl
    },
    toRegister() {
      this.$router.push('/register')
    }
  }
}
</script>

<style lang="scss" scoped>
.login-content {
  height: 600px;
  background-image: url("../../../../assets/images/login/login-bg.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  .bottom-icon {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 1000px;
    margin: 10px auto;
  }
  .login-form-parent {
    width: 550px;
    height: 100%;
    position: absolute;
    right: 10%;
    display: flex;
    align-items: center;
    .login-form {
      width: 550px;
      height: 430px;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 10px;
      padding: 0 30px;
      .login-title {
        text-align: center;
        margin-bottom: 35px;
        font-size: 25px;
      }
      .login-input {
        i {
          color: rgb(250, 59, 30);
          font-size: 20px;
        }
      }
      #img {
        width: 200px;
        height: 50px;
        cursor: pointer;
        margin-left: 20px;
        border-radius: 5px;
      }
    }
  }
}
::v-deep .el-input__inner {
  height: 37px;
  border-radius: 15px;
}
.remember-account {
  font-size: 14px;
  font-weight: 400;
}
</style>
